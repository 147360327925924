body{
  background-color: #cac9c9;
  color: #000;
  font-family: 'Comfortaa', cursive;
}

h2{
  font-size: 2.5rem;
}

a {
  color: royalblue;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  text-decoration: none;
}

.logo{
  float: left;
  height: 34px;
  margin: 3px;
  margin-right: 10px;
}

.root{
  z-index: 30;
  position: relative;
}

.backgound-l-column{
  left: 0;
  background-color: #dadada;
  position: absolute;
  min-height: 100vh;
  height: 100%;
  top: 0;
  z-index: 5;
}
.backgound-r-column{
  z-index: 5;
  background-color: #181a29;
  position: absolute;
  min-height: 100vh;
  height: 100%;
  top: 0;
  z-index: 5;
}


@media (min-width: 1200px){
  .backgound-l-column {
    width: 70%;
  }
  .backgound-r-column{
    width: 30%;
  }
}
@media (min-width: 992px){
  .backgound-l-column {
    position: absolute;
    min-height: 100vh;
    height: 100%;
    top: 0;
    z-index: 5;
  }
}
@media (min-width: 768px){
  .backgound-l-column {
    position: absolute;
    min-height: 100vh;
    height: 100%;
    top: 0;
    z-index: 5;
  }
}
@media (min-width: 576px){
  .backgound-l-column {
    position: absolute;
    min-height: 100vh;
    height: 100%;
    top: 0;
    z-index: 5;
  }
}
@media (max-height: 930px){
  .hidden-low-width{
    display: none;
  }
}


.iconText {
  text-align: center;
  font-size: 25px;
  color: #000;
}

.secondText{
  text-align: center;
  font-weight: 600;
  color: #000;
}

.row-m{
  margin-top: 40px;
  margin-bottom: 40px;
}

.left-column-p{
  margin-top: 6%;
  padding-top: 4%;
  padding-left: 4%;
}
.right-column-p{
  margin-top: 8%;
}
.right-column-p p{
  font-size: 18px;
  letter-spacing: 2px;
}

@media (min-width: 1200px){
  .right-column-p{
    padding-top: 4%;
    padding-left: 6%;
    padding-right: 6%;
  }
}

.t-l{
  text-align: left;
}

.t-c{
  text-align: center;
}

.t-r{
  text-align: right;
}


@media (min-width: 992px){
  .lg-t-c{
    text-align: center;
  }
}
@media (min-width: 768px){
  .md-t-c{
    text-align: center;
  }
  .right-column-p{
    padding-left: 8%;
  }
}

@media (max-width: 576px){
  .title{
    font-size: 1.5rem;
  }
  .xs-t-c{
    text-align: center;
  }
  .logo{
    display: none;
  }
  .header-phone{
    font-size: 1.5rem;
  }
  .right-column-p{
    padding-left: 6%;
    padding-right: 6%;
  }
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.beforeform{
  font-weight: 600;
}

.lform{

}

.l-form-label{
  color: #000;
  font-size: 18px;
  letter-spacing: 2px;
}

.l-form-input{
  border-radius: 10px;
  cursor: pointer;
  border: none;
  width: 100%;
  height: 40px;
  padding: 10px;
  transition: .3s;
}
.l-form-input:hover{
  box-shadow: 0 5px 20px rgba(46, 136, 229, 0.4);
  outline: none;
}
.l-form-input:focus{
  box-shadow: 0 5px 20px rgba(46, 136, 229, 0.4);
  border: 2px solid rgba(72, 144, 218, 0.82);
  outline: none;
}

.l-form-button{
  text-decoration: none;
  outline: none;
  display: inline-block;
  height: 45px;
  line-height: 45px;
  border: none;
  border-radius: 45px;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  margin-top: 20px;
  letter-spacing: 3px;
  font-weight: 600;
  color: #04071b;
  background: white;
  box-shadow: 0 8px 15px rgba(0,0,0,.1);
  transition: .3s;
}

.l-form-button:hover{
  background: #3f9ee4;
  box-shadow: 0 5px 20px rgba(46, 136, 229, 0.4);
  color: #04071b;
  transform: translateY(-7px);
}
.l-form-button:focus{
  outline: none;
}

.r-c-description{
  margin-top: 30px;
}

.sociallinks{
  text-align: center;
}

.telegram{
  background-color: #3f9ee4;;
  padding: 16px 18px;
  border-radius: 80px;
  color: white;
  font-size: 18px;
  transition: .3s;
}

.telegram:hover{
  color: white;
  background-color: #636465;
}

.whatsapp{
  background-color: #4cd964;
  padding: 16px 18px;
  border-radius: 80px;
  color: white;
  font-size: 18px;
  transition: .3s;
}

.whatsapp:hover{
  color: white;
  background-color: #636465;
}

.fa-hand-point-right{
  font-size: 20px;
}

.cursor-str{
  border-right: 2px solid #000;
  padding-right: 5px;
}

.under-line-red{
  border-bottom: 2px solid rgba(72, 144, 218, 0.82);
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ease-in{
  transition: all 1000ms ease-in-out;
}
.open{
  display: block;
  transition: all 1000ms ease-in-out;
}
.hidden{
  display: none;
  transition: all 1000ms ease-in-out;
}

.b-toggle {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  -moz-transition: opacity .3s, max-height .3s;
  -ms-transition: opacity .3s, max-height .3s;
  transition: opacity .3s, max-height .3s;
  _display: none;
}

.expanded {
  max-height: 340px;
  opacity: 1;
  _display: block;
  -moz-transition: opacity .3s, max-height .3s;
  -ms-transition: opacity .3s, max-height .3s;
  transition: opacity .3s, max-height .3s;
}

.hidden_popup_button{
  text-align: center;
  font-size: 18px;
  color: #4c4c4c;
  cursor: pointer;
}

.hidden_description_close{
  position: absolute;
  top: 0px;
  background-color: aliceblue;
  border-radius: 10px;
  padding: 10px;

  opacity: 0;
  max-height: 0;
  overflow: hidden;
  -moz-transition: opacity .3s, max-height .3s;
  -ms-transition: opacity .3s, max-height .3s;
  transition: opacity .3s, max-height .3s;
}

.hidden_description{
  background-color: aliceblue;
  border-radius: 10px;
  padding: 10px;
  position: absolute;
  top: 0px;
  max-height: 340px;
  opacity: 1;
  transition: opacity .3s, max-height .3s;
  z-index: 5;
  box-shadow: 0px 5px 5px #00000085;
  margin-right: 15px;
}

.close_button{
  text-align: right;
}

.close_button button{
  background-color: aliceblue;
  border: none;
  text-align: right;
  color: royalblue;
}